<template>
  <div>
    <TableFilters :queries="queries">
      <template v-slot:filter-title>
        <span v-if="sourceObject.count">
          <span class="title-filter">Всего:</span>{{ sourceObject.count }}
        </span>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      disable-router
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    >
    </TableLayout>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsAccess from "@/helpers/tableCols/access";

export default {
  name: "CardsPage",
  data() {
    return {
      loadingBtn: false,
      queries: query.access.filter(el => el.type !== "search")
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      return tableColsAccess.filter(el => {
        return el.text !== "ФИО";
      });
    },
    sourceObject() {
      return this.$store.getters.getPageAccessList;
    },
    urlQuery() {
      return {
        id: this.$route.params.id,
        query: this.$route.query
      };
    }
  },
  methods: {},
  created() {
    this.$store.dispatch("clearSortQuery");

    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        user: this.$route.params.id
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageAccessListFromUser").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchPageAccessListFromUser").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
};
</script>
