<template>
  <div>
    <TableLayout
      :loading="isDateLoaded"
      disable-router
      :table-data="tableData"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import tableColsPayment from "@/helpers/tableCols/payment";
export default {
  name: "UsersPayments",
  data() {
    return {
      // type: "default",
      queries: query.payments.filter(
        el =>
          (!this.$route.params.dealerId || el.type !== "diller") &&
          (!this.$route.params.companyId || el.type !== "organization") &&
          (!this.$route.params.objectId || el.type !== "object") &&
          el.type !== "search"
      )
    };
  },
  components: { TableLayout },
  computed: {
    header() {
      return tableColsPayment.filter(
        el =>
          el.userRole.includes(this.$store.getters.getCurrentRole) &&
          el.text != "ФИО" &&
          el.text != "Телефон"
      );
    },
    tableData() {
      return this.$store.getters.getUserPayments.results;
    },
    sourceObject() {
      return this.$store.getters.getUserPayments;
    },
    urlQuery() {
      return {
        id: this.$route.params.id,
        query: this.$route.query
      };
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        user: this.$route.params.id,
        status__in: "completed,awa-card"
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchUserPaymentsKindergartensList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store
          .dispatch("fetchUserPaymentsKindergartensList")
          .finally(() => {
            this.isDateLoaded = false;
          });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearPaymentsPage").then(() => next());
  // }
};
</script>

<style scoped></style>
